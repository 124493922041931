import axios from "axios";
import config from "../configs/config";

const mainAPI = {
  requestHandler(header, body) {

    return axios
      .post(
        `${config.baseApiUrl}/requesthandler`,
        JSON.stringify(body),//this is the request body
        {
          headers: header
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let res = err.response;
        return res;
      });
  },
};

export default mainAPI;
