import axios from "axios";
import config from "../configs/config";

const myspilcomapi = {
  listFaqNew2(header, body) {
    return axios
      .post(
        `${config.myspilcomApiUrl}/func/listfaqnew2.json`,
        JSON.stringify(body) //this is the request body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let res = err.response;
        return res;
      });
  },
  spilcoidContactUs(header, body) {
    return axios
      .post(
        `${config.myspilcomApiUrl}/spilcoid/spilcoidcontactus.json`,
        JSON.stringify(body) //this is the request body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let res = err.response;
        return res;
      });
  },
  spilcoidEsgOtpEmail(header, body) {
    return axios
      .post(
        `${config.myspilcomApiUrl}/spilcoid/spilcoidesgotpemail.json`,
        JSON.stringify(body) //this is the request body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let res = err.response;
        return res;
      });
  },
};

export default myspilcomapi;
