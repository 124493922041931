// import https from "https";

// const agent = new https.Agent({
//   rejectUnauthorized: false,
// });

let config = {
  //spilcoid
  // baseApiUrl: "http://localhost:3005/spilcoidapi", // local
  baseApiUrl: "https://nanika.spil.co.id:8082/spilcoidapi", //server_prod
  // baseApiUrl: "http://nanika.spil.co.id:8089/spilcoidapi", //server_dev
  secretKey: "xuqAsjYDg2jtdPmpR4AZ",

  //spildex
  spildexApiUrl: "https://nanika.spil.co.id:8084/spildex",
  spildexUsername: "baeb66f7a6bd04122898a664a332cd1e82b93b01",
  spildexPassword: "a57ef7a10b8efdbca23fe179c7c5e9ec30bca6d4",

  //myspilcomapi
  // myspilcomApiUrl : "http://localhost:8080/myspilcomapiweb/api", //LOCAL
  // myspilcomApiUrl : 'http://api3.spil.co.id:7001/myspilcomapiweb_dev2/api',   //SERVER DEV BARU 2
  // myspilcomApiUrl : 'http://api3.spil.co.id:7001/myspilcomapiweb_dev_iklil/api',   //SERVER DEV SEMENTARA
  // myspilcomApiUrl : 'https://usopp.spil.co.id/myspilcomapiweb/api', //API MYSPIL PROD NGNX
  myspilcomApiUrl: 'https://usopp.spil.co.id/myspilcomapiweb_temp/api', //API MYSPIL PROD NGNX TEMP
};

export default config;
