const englishWords = {
  // Navbar menu
  navHome: "Home",
  navCommitment: "Sustainability",

  navCompany: "Company",
  navAbout: "About SPIL",
  navHistory: "History",
  navGroup: "SPIL Group",
  navCareer: "Career",

  navServices: "Services",
  navServicesType: "Services Type",
  navGuide: "Guides",
  navRoute: "Routes and Schedules",

  navContact: "Contact Us",
  navMyspilReloaded: "mySPIL Reloaded",

  navLang: "ENG",
  navID: "ID",
  navENG: "ENG",

  // Footer Menu
  navSpilDex: "SPILDex",
  navNews: "News",
  navOutlook: "Insight",

  // HomePage
  homeHeroTitle: "Driving the National Economy",
  homeAboutCaption: "About Us",
  homeAboutTitle: "SPIL Connecting Islands",
  homeAboutDesc:
    "Salam Pacific Indonesia Lines (SPIL) operates in the Container Shipping sector, with a logistics network of more than 38 ports spread throughout Indonesia. Supported by dozens of container ships, hundreds of heavy equipment and thousands of containers. With supporting operational facilities and reliable personnel, we continue to develop and expand to improve service and customer satisfaction.",
  homeAboutCTA: "Who We Are",
  homeDataTitle: "Spread throughout Indonesia",
  homeDataPort: "Ports",
  homeDataRoute: "Routes",
  homeDataSentContainer: "Containers Sent",
  homeDataServing: "Our Commitment to Serve",
  homeServiceCaption: "Our Services",
  homeServiceTitle: "One Stop Logistics Solutions",
  homeServiceSubtitle: "Various services for all your needs",
  homeServiceCTA: "Solution we offer",
  homeServicePTP: "Port to Port",
  homeServicePTPDesc: "Delivery of goods from one port to another",
  homeServiceDTD: "Door to Door",
  homeServiceDTDDesc:
    "Delivery of goods from the seller's initial door (storage place) to the buyer's destination door.",
  homeServiceAirfreight: "Air Freight",
  homeServiceAirfreightDesc:
    "Transport your goods safely and quickly via airplane.",
  homeServiceLandTransport: "Land Transport",
  homeServiceLandTransportDesc: "Transport your goods by road using our fleet",
  homeServiceLCL: "Less Container Load",
  homeServiceLCLDesc:
    "Send your goods without having to fill a container, it's more economical & more flexible.",
  homeServiceInternational: "International Shipment",
  homeServiceInternationalDesc:
    "In collaboration with TOLL Group, we serve deliveries outside Indonesia.",
  homeServiceInsurance: "MAG Insurance",
  homeServiceInsuranceDesc:
    "Protect your goods from loss and damage during the shipping process.",
  homeServiceWarehousing: "Warehousing",
  homeServiceWarehousingDesc:
    "Modern warehouse with a strategic location, and has quick access from the container depot.",
  homeNewsCaption: "News & Activities",
  homeNewsTitle: "Our Latest Activities",
  homeNewsCTA: "Our Activities",
  homeNewsCTADetail: "Read More",
  homeBook: ["Interested?", "Start Your Delivery Now"],
  homeBookCTA: "Book Now",

  // Commitment Page
  commitmentTitle: "Caring for Community and Planet",
  commitmentHeader1: "Our Responsibility",
  commitmentDesc1: [
    "PT SPIL is a shipping and logistics company that shows its commitment to environmental, social and governance (ESG) principles. With more than 50 years of experience, we always try and learn in depth about the impact of a business on the earth, through the ESG initiatives that have been prepared, we strive to always take a comprehensive approach to sustainability.",
    "Applying environmentally friendly technologies and practices, SPIL ensures carbon footprint calculation and responsible resource management. At the same time, our efforts towards social empowerment focus on creating an inclusive workplace, respecting diversity, employee welfare and actively contributing to the progress of the surrounding community.",
    "We maintain transparency and accountability, foster trust among stakeholders and strive to bring about meaningful change. We believe our actions can inspire others to do the same and collaborate together towards a better future.",
  ],
  commitmentHeader2: "Sustainability Achievement",
  commitmentDesc2:
    "At PT SPIL, we are more than just a company; we are a company that supports Environmental, Social and Governance (ESG). With our clear commitment to Sustainability, our actions reflect our belief in creating a positive impact on the world we live in today.",
  commitmentSlider1:
    "Small Habits That Can Change the World with Plastic Bottle Recycling Machines",
  commitmentSlider2:
    "Committed to Protecting Children from Violence on National Children's Day",
  commitmentSlider3: "Care about Children's Health and Hygiene",
  commitmentSlider4: "Grow New Hopes in Plants in the New Year",
  commitmentSlider5: "Spread Kindness Through the Zero Hunger Campaign",
  commitmentSlider6: "SPIL Cares for the Nation's Children",
  commitmentModalTitle: "Download ESG Report",
  commitmentModalTitleCaption:
    "Fill your personal information to download ESG Report",
  commitmentModalOTPTitle: "Enter OTP Code",
  commitmentModalOTPTitleCaption:
    "Check your email to obtain a 6-digit OTP code.",
  commitmentModalCompanyName: "Company Name",
  commitmentModalCompanyNamePlaceholder: "Company Name",
  commitmentModalName: "Name",
  commitmentModalNamePlaceholder: "Full Name",
  commitmentModalMaleTitle: "Mr",
  commitmentModalFemaleTitle: "Mrs",
  commitmentModalWANumber: "Phone Number",
  commitmentModalWANumberPlaceholder: "Phone Number",
  commitmentModalEmail: "Email",
  commitmentModalEmailPlaceholder: "Active Email",
  commitmentModalSubmitCTA: "Next",
  commitmentModalResendOTP: "Dont receive code?",
  commitmentModalTooltip: "Click here to download",

  // Company Page - About
  companyAboutTitle: "About SPIL",
  companyAboutSubtitle:
    "Find out all the information related to PT Salam Pacific Indonesia Lines",
  companyAboutHeader: "Connecting Islands",
  companyAboutDesc: [
    "PT Salam Pacific Indonesia Lines (SPIL) is a container shipping company headquartered in Surabaya, Indonesia. SPIL is the largest technology-based container shipping company in Indonesia & currently serves more than 40 ports spread throughout Indonesia.",
    "This company was founded in 1970, supported by an extensive network throughout Indonesia and quality resources. Until now, SPIL continues to innovate and develop to provide quality services to help your business continue to grow and develop. SPIL believes that continuous innovation can help you to overcome the complexity of today's business environment and we are ready to be your partner in facing global challenges.",
  ],
  companyAboutVisi: "Vision",
  companyAboutVisiValue:
    "To become the best shipping company that drives the economy",
  companyAboutMisi: "Mission",
  companyAboutMisiValue:
    "Providing transportation solutions with the widest network supported by quality resources for customer satisfaction",
  companyAboutSPILValues: "SPIL Value",
  companyAboutSPILValueIntegrity: "Integrity",
  companyAboutSPILValueCustomer: "Customer Orientation",
  companyAboutSPILValueCompetitive: "Competitive",
  companyAboutSPILValueTeamwork: "Teamwork",
  companyAboutSPILValueVisionary: "Visionary",

  // Company Page - History
  companyHistoryTitle: "Company history",
  companyHistorySubtitle: "Check out SPIL's developments from time to time.",
  companyHistory1970Title: "Beginning of the Journey",
  companyHistory1970Desc:
    "The journey began in 1970 with the formation of PT SAMUDERA PACIFIC as an inter-island goods transportation services company.",
  companyHistory1980Title: "First Ship",
  companyHistory1980Desc:
    "The first ship marked the beginning of the birth of a national shipping company called PT SAMUDERA PACIFIC INDAH RAYA (SPIR).",
  companyHistory1984Title: "SPIL Expansion",
  companyHistory1984Desc:
    "The company acquired shares in PT SALAM SEJAHTERA located in Samarinda and moved its head office to Surabaya as a sign of the birth of PT SALAM PACIFIC INDONESIA LINES (SPIL).",
  companyHistory1996Title: "SPIL Containerization",
  companyHistory1996Desc:
    "In 1996, SPIL developed its business from bulk carriers to more efficient shipping using containers.",
  companyHistory2000Title: "Reaching Eastern Indonesia",
  companyHistory2000Desc:
    "This year PT SPIL is the first shipping service to deliver to Sorong City. Contribute to the economic and market growth of Eastern Indonesia.",
  companyHistory2009Title: "Connecting Indonesia",
  companyHistory2009Desc:
    "From customers' trust in us, this year SPIL has more than 20 branches spread throughout the country.",
  companyHistory2014Title: "Our Commitment",
  companyHistory2014Desc:
    "PT SPIL received several awards from Pelindo, the Ministry of Transportation, and other agencies. This is proof of PT SPIL's commitment to realizing its vision as a driver of the national economy, in the fields of logistics and transportation.",
  companyHistory2017Title: "MYSPIL App",
  companyHistory2017Desc:
    "Launching mySPIL as the first application in the shipping and logistics industry to implement digitalization that improves customer experience.",
  companyHistory2020Title: "International Freight Forwarding",
  companyHistory2020Desc:
    "Establishment of PT SPIL Toll Indonesia, a collaboration between SPIL and Toll Group (a member of Japan Post).",
  companyHistoryPresentTitle: "All In One Logistic Solutions",
  companyHistoryPresentDesc:
    "PT SPIL provides various logistics solutions, from sea freight, air freight, to land transport. With various existing services, through one platform.",

  // Company Page - Group
  companyGroupTitle: "SPIL Group",
  companyGroupSubtitle:
    "In such a long journey, we continue to develop to meet all your logistics needs.",
  companyGroupCTA: "See More",
  companyGroupSPIL:
    "SPIL is a shipping logistics company (Shiplog) that has a network throughout Indonesia, providing logistics and shipping solutions to help your business continue to grow and develop.",
  companyGroupNPTI:
    "NPTI is a container terminal company located at Tanjung Perak Port, Surabaya, the gateway to Eastern Indonesia. NPTI is committed to running the terminal efficiently and effectively using modern technology.",
  companyGroupNPH:
    "Located at Tanjung Priok Port, Jakarta, Adipurusa serves domestic container terminals in collaboration with PT Nusantara Pelabuhan Handal Tbk., a leading company that provides complete port services.",
  companyGroupNYK:
    "Together with NYK, provides comprehensive domestic delivery services for automotive and heavy equipment.",
  companyGroupManalagi:
    "The increasing demand for domestic breakbulk transportation prompted SPIL to establish its bulk division, PT Pelayaran Mana Lagi. PT Pelayaran Mana Lagi currently owns and operates 4 supramax and 1 handisize vessels.",
  companyGroupDOK:
    "Present to serve regional ship repairs by prioritizing safety, quality and timeliness. Has been certified ISO 9001, ISO 14001, OHSAS 18001 and SMK3.",
  companyGroupTOLL:
    "In collaboration with Toll Group, an international logistics provider with 130 years of experience in the logistics and supply chain sector, PT STI manages domestic and international shipping, as well as local contract logistics and goods distribution.",
  companyGroupVentures:
    "Unlocking the potential for faster growth in the digital supply chain era. We aim to utilize and develop brilliant and brilliant ideas so that they become a successful form of business.",
  companyGroupTPIL:
    "PT TPIL was established in the year 2000 as a subsidiary of the SPIL group. Our company has evolved from a domestic freight forwarding firm into one of the first companies to provide comprehensive services. we are growing and have more than 30 branches in Indonesia.",

  // Company Page - Career
  companyCareerTitle: "Growing Together",
  companyCareerListHeader: "Find Your Opportunities",
  companyCareerTabProfessional: "Professional",
  companyCareerTabCrew: "Ship crew",
  companyCareerTabIntern: "Cadet / Apprentice",
  companyCareerGreenerHeader:
    "We are looking for Greeners, to together green the ocean. Are you the one?",
  companyCareerGreenerDesc:
    "Discover new opportunities for your future and grow together with our values and culture.",
  companyCareerBusinessHeader: "Excellent Business",
  companyCareerBusinessDesc:
    "We are proud to have been part of our customers' success for more than 50 years, proving the quality, consistency and sustainability of our business to continue to drive the national economy.",
  companyCareerGrowthHeader: "Growing Together",
  companyCareerGrowthDesc:
    "At SPIL, we believe you are an important element of our success and sustainability. Not only routine work, SPIL also provides various learning and training tailored to your competencies and needs. Achieving the career you dream of is no longer just a dream.",
  companyCareerInovationHeader: "Always Innovate",
  companyCareerInovationDesc:
    "Like ocean waves that never stop moving, SPIL always innovates to keep up with the current developments. A dynamic work culture that encourages each employee to dare to voice their aspirations, here you can be part of a change.",
  companyCareerConcernHeader: "Concern for Others",
  companyCareerConcernDesc:
    "Greeners are not just a color, name or icon, but also a family. We uphold diversity and care about each other. The distance between our offices located from western to eastern Indonesia is not an obstacle to remaining united and fighting together.",
  companyCareerMomentHeader: "Special Moments in Special Environments",

  // Company Page - Career Details
  companyCarrerDetailBack: "back to career list",
  companyCareerDetailJobHeader: "Job Details",
  companyCareerDetailLevel: "Level",
  companyCareerDetailLocation: "Location",
  companyCareerDetailType: "Type",
  companyCareerDetailDesc: "Description",
  companyCareerDetailQualification: "Qualification",
  companyCareerDetailEducation: "Education",
  companyCareerDetailExperience: "Experience",
  companyCareerDetailCTA: "Apply for This Job",

  // Service Page - Type
  serviceTypeTitle: "One Stop Logistics Solutions",
  serviceTypeServices: "Services Type",
  serviceTypePTPHeader: "Port to Port",
  serviceTypePTPDesc:
    "Port to Port can be interpreted as where the shipper or goods sender delivers the goods to the shipping company appointed at the shipper's place of origin, and sends them to the port receiving the goods. And the recipient of the goods or consignee also picks it up himself at the port specified by the consignee himself.",
  serviceTypeDTDHeader: "Door to Door",
  serviceTypeDTDDesc:
    "Door to Door service in the world of cargo is a service where the delivery method of goods will be picked up or picked up at the sender's location and delivered to the location of the recipient of the goods or consignee.",
  serviceTypeLogistic: "Logistics Solutions",
  serviceTypeSeaFreightHeader: "Sea Freight",
  serviceTypeSeaFreightDesc:
    "Sea Freight Services is the delivery of goods through sea waters. Sea Freights is an economical choice for sending goods in large volumes and long distances.",
  serviceTypeAirFreightHeader: "Air Freight",
  serviceTypeAirFreightDesc:
    "Air Freight Services offers delivery of goods in a matter of hours, wider & safer delivery range. Currently it is integrated and can be accessed on the mySPIL Reloaded application.",
  serviceTypeLandTransportHeader: "Land Transport",
  serviceTypeLandTransportDesc:
    "Land Transport delivery has high flexibility based on time, needs and area. Land Transport has a wide reach so it can reach all areas, from small areas such as villages, to entire cities.",
  serviceTypeOther: "Other Services",
  serviceTypeInternationalHeader: "International Freight Forwarder",
  serviceTypeInternationalDesc:
    "International Freight Forwarder is a term used to describe a package delivery or group of package deliveries where the package is picked up from one country and sent to an address in another country. In this case, SPIL synergizes with TOLL to become SPIL TOLL Indonesia.",
  serviceTypeWarehouseHeader: "Warehouse",
  serviceTypeWarehouseDesc:
    "As an integrated logistics services company, SPIL provides modern warehouses supported by modern design concepts, strategic locations, and has fast access from container depots for inter-island deliveries.",
  serviceTypeInsuranceHeader: "MAG Insurance",
  serviceTypeInsuranceDesc:
    "MAG INSURANCE provides protection against loss and damage to cargo resulting from maritime hazards that occur during the transportation of your cargo, both by sea and land. By including MAG Insurance you will have more peace of mind when sending goods with SPIL. For further information, ",

  // Service Page - Guide
  serviceGuideHeaderTitle: "Getting confused with your problem?",
  serviceGuideHeaderFormTitle: "How can we help?",
  serviceGuideHeaderFormPlaceholder:
    "Enter a topic, such as payment, shipping, seal, etc.",
  serviceGuideHeaderCTA: "Search",
  serviceGuideTopicHeader: "Search By Topic",
  serviceGuideSeeMore: "See more",
  serviceGuideSeeLess: "See less",
  serviceGuideNoDataFound: "Guide not found",
  serviceGuideFirstSender: "First Sender",
  serviceGuideFirstSenderOpt1: "First Sender",
  serviceGuideFirstSenderOpt2: "About dangerous goods",
  serviceGuidePaymentMethod: "Payment method",
  serviceGuidePaymentMethodOpt1: "Payment method",
  serviceGuidePaymentMethodOpt2: "Payment via banks accepted by SPIL",
  serviceGuidePaymentMethodOpt3: "Access myFinance",
  serviceGuidePaymentMethodOpt4: "Payment by credit system",
  serviceGuideSeal: "Seal",
  serviceGuideSealOpt1: "Purchase seals on mySPIL Reloaded",
  serviceGuideSealOpt2: "Purchase seals on mySPIL Reloaded (pickup)",
  serviceGuideAirFreight: "Air Freight",
  serviceGuideAirFreightOpt1: "Our Air Freight Services",
  serviceGuideAirFreightOpt2: "How the Air Freight service works",
  serviceGuideAirFreightOpt3: "Benefits of using Air Freight services",
  serviceGuideAirFreightOpt4: "Air Freight cargo type",
  serviceGuideMySPILReloaded: "mySPIL Reloaded",
  serviceGuideMySPILReloadedOpt1: "Register a mySPIL account",
  serviceGuideMySPILReloadedOpt2:
    "MySPIL access rights type after registration",
  serviceGuideMySPILReloadedOpt3: "Submission of mySPIL access rights type",
  serviceGuideMySPILReloadedOpt4: "Benefits of using mySPIL",
  serviceGuideMyBooking: "myBooking",
  serviceGuideMyBookingOpt1: "Quick Booking feature",
  serviceGuideMyBookingOpt2: "MySPIL access rights type after registration",
  serviceGuideMyBookingOpt3: "Submission of mySPIL access rights type",
  serviceGuideMyBookingOpt4: "Benefits of using mySPIL",
  serviceGuideMyDO: "myDO",
  serviceGuideMyDOOpt1: "eDO submission using Shippper Confirmation",
  serviceGuideMyDOOpt2: "Get verification code",
  serviceGuideMyDOOpt3: "Submission of eDO extension",
  serviceGuideMyDOOpt4: "eDO Payments",
  serviceGuideMyShipment: "myShipment",
  serviceGuideMyShipmentOpt1: "Create e-SI (Shipping Instruction)",
  serviceGuideMyShipmentOpt2: "Access draft Shipping Instructions",
  serviceGuideMyShipmentOpt3:
    "Change the Shipping Instruction that has been entered",
  serviceGuideMyShipmentOpt4: "Knowing that the BL has been processed",
  serviceGuideMySPILPoints: "mySPIL Points",
  serviceGuideMySPILPointsOpt1: "About mySPIL Points",
  serviceGuideMySPILPointsOpt2: "How to Get mySPIL Points",
  serviceGuideMySPILPointsOpt3: "Calculation of mySPIL Points Value",
  serviceGuideMySPILPointsOpt4: "mySPIL Points Validity Period",
  serviceGuideGuarantee: "Guarantees & Claims",
  serviceGuideGuaranteeOpt1: "Claim Container Guarantee on mySPIL",
  serviceGuideGuaranteeOpt2: "Make a Claim on mySPIL Reloaded",
  serviceGuideBill: "Bill Payments & Topup",
  serviceGuideBillOpt1: "Explanation of Bill Payments & Topup",
  serviceGuideBillOpt2: "Bill Payments & Topup feature",
  serviceGuideBillOpt3: "Perform Bill Payments & Topup",
  serviceGuideMySPILCall: "mySPIL Call",
  serviceGuideMySPILCallOpt1: "mySPIL Call feature",
  serviceGuideMySPILCallOpt2: "Make calls using mySPIL Call",
  serviceGuideMySPILCallOpt3: "Benefits of using mySPIL Call",
  serviceCTAHeader: "Need solution to solve your problem?",
  serviceCTA: "Contact us",

  // Service Page - Guide Detail
  serviceGuideDetailCTABack: "back to topic list",
  serviceGuideChoosenTopic: "Selected topic",

  // Service Page - Route and Schedule
  serviceRouteHeaderTitle: "Vessel Schedule",
  serviceRouteHeaderFormTitlePOL: "Port of Loading",
  serviceRouteHeaderFormPlaceholderPOL: "Select Origin City",
  serviceRouteHeaderFormTitlePOD: "Port of Discharge",
  serviceRouteHeaderFormPlaceholderPOD: "Select Destination City",
  serviceRouteHeaderCTA: "Book Now",
  serviceRouteAvailableSchedule: "Available Vessel Schedule",
  serviceRouteFreq: "Frequency",
  serviceRouteChooseArea: "Select Branch Area",

  // Contact Us Page
  contactWhatsappDesc: "Contact our WhatsApp to get an accurate response",
  contactWhatsappCTA: "Chat Now",
  contactCSDesc:
    "SPIL Customer Service is ready to help with everything you need.",
  contactCSCTA: "Call CS",
  contactEmailTitle: "Send Message",
  contactEmailDesc:
    "Is there anything you want to ask? Send details via message.",
  contactEmailCTA: "Send Email",
  contactVisitUs: "Visit Us",
  contactOurBranch: "Our Branch",
  contactContactUs: "Contact Us",
  contactFormName: "Name",
  contactFormNamePlaceholder: "Full Name",
  contactFormWA: "Whatsapp Number",
  contactFormWAPlaceholder: "Active Whatsapp Number",
  contactFormEmail: "Email",
  contactFormEmailPlaceholder: "Active Email",
  contactFormRequire: "Necessity",
  contactFormRequirePlaceholder: "Select Requirements",
  contactFormMessage: "Message",
  contactFormMessagePlaceholder: "I need ...",

  // mySPILReloaded Page
  mySPILReloadedTitle: "Logistics Services in Your Hands",
  mySPILReloadedDesc:
    "PT Salam Pacific Indonesia Lines (SPIL) through the mySPIL Reloaded application, continues to innovate to develop the first and most complete Logistics Super App in Indonesia. mySPIL Reloaded provides the most complete multimodal services for Indonesian logistics businesses, shipping goods by sea, land and air. It was launched in 2017, and until now continues to transform to respond to changing times and customer needs.",
  mySPILReloadedFeature: "mySPIL Reloaded Main Features",
  mySPILReloadedWhy: "Why mySPIL Reloaded?",
  mySPILReloadedEfficientHeader: "Efficient and Easy to Use",
  mySPILReloadedEfficientDesc:
    "The simple appearance of the mySPIL Reloaded application is easier than the previous version and will save your time.",
  mySPILReloadedFeatureHeader: "Various Service Features in One Platform",
  mySPILReloadedFeatureDesc:
    "mySPIL Reloaded is equipped with various features to be able to place orders practically, comfortably and safely.",
  mySPILReloadedRewardHeader: "Rewards and Usage Bonuses",
  mySPILReloadedRewardDesc:
    "The mySPIL Reloaded application also comes with rewards and bonuses for users, mySPIL points and mySPIL Referral Program.",
  mySPILReloadedCTA: "Register to mySPIL Reloaded",

  // News Page
  newsTitle: "News & Activities",
  newsCTABack: "back to news list",
  newsLatestNews: "Latest News",
  newsCategory: "Category",
  newsShare: "Share",
  newsArchive: "Archives",
  newsFilterArchive: "You are currently viewing news from the year ",
  newsFilterCategory: "You are viewing news in the",

  // Outlook Page
  outlookTitle: "Gain New Insights",
  outlookLatest: "Latest Insights",
  outlookCTABack: "back to insight list",

  // SPILDex Page
  spildexTitle: "Stay Updated, Stay Connected",
  spildexDesc:
    "SPILDEX is a special service for companies and provides an Application Programming Interface (API) that can be integrated with a company's Enterprise Resource Planning (ERP) system to make it easier to access data in real time safely without having to know each company's internal processes. Access to this data includes vessel schedule, booking list and status, track and trace, statement of account, invoice, and online payment (via virtual account) with ease of processing information according to the format desired by the company.",
  spildexVesselHeader: "Vessel Schedule",
  spildexVesselDesc:
    "You can easily access vessel schedules through the Vessel Schedule feature and also find out the activities of vessels departure, arriving and docking in the application your company uses with API and ERP integration.",
  spildexPaymentHeader: "e-Payment",
  spildexPaymentDesc:
    "Use the online payment feature (via Virtual Account) to make more practical payments and save your time because you don't need to come to our offices / branches.",
  spildexMyBookingHeader: "myBooking",
  spildexMyBookingDesc:
    "Booking is even easier with the Booking List & Status feature, you no longer need to download various files from SPIL just to make a booking, besides that you can also see the booking status in real time.",
  spildexShipmentHeader: "e-Shipment Instruction",
  spildexShipmentDesc:
    "With the Statement of Account feature, you can view shipping bills directly from the company application, without moving to another application.",
  spildexTraceHeader: "Track and Trace",
  spildexTraceDesc:
    "Use our Track & Trace feature to track the current position of your shipment by entering information from the container number, BL number, ship name, port name of origin and destination, or booking code.",
  spildexWhy: ["Why", "SPILDex"],
  spildexServiceHeader: "Various Service",
  spildexServiceDesc:
    "Vessel schedule, Order list, Track & Trace, Payment history, and Online payments. All the information you need is within your reach.",
  spildexWebhooksHeader: "Webhooks in Real-Time",
  spildexWebhooksDesc:
    "With our API, you can access data in your ERP in real-time and receive notification callbacks such as container movements, ship departures, and much more.",
  spildexCustomHeader: "Customized",
  spildexCustomDesc:
    "The appearance of each feature in the application can be adjusted to the format desired by the Company.",
  spildexOperationHeader: "Operational Efficiency",
  spildexOperationDesc:
    "With all the features above, you can be sure that all processes, especially operations, will run more efficiently.",
  spildexInterest: [
    "Are you interested ?",
    "Go integrate Your Business with SPILDEX",
  ],
  spildexCTA: "Contact us",
};

export default englishWords;
