const indonesiaWords = {
  // Navigation
  // Navbar menu
  navHome: "Beranda",
  navCommitment: "Keberlanjutan",

  navCompany: "Perusahaan",
  navAbout: "Tentang SPIL",
  navHistory: "Sejarah",
  navGroup: "Grup SPIL",
  navCareer: "Karir",

  navServices: "Layanan",
  navServicesType: "Jenis Layanan",
  navGuide: "Panduan",
  navRoute: "Rute dan Jadwal",

  navContact: "Hubungi Kami",
  navMyspilReloaded: "mySPIL Reloaded",

  navLang: "ID",
  navID: "ID",
  navENG: "ENG",

  // Footer Menu
  navSpilDex: "SPILDex",
  navNews: "Berita",
  navOutlook: "Wawasan",

  // HomePage
  homeHeroTitle: "Menggerakkan Perekonomian Nasional",
  homeAboutCaption: "About Us",
  homeAboutTitle: "SPIL Connecting Islands",
  homeAboutDesc:
    "Salam Pacific Indonesia Lines (SPIL) bergerak di bidang Container Shipping, dengan jaringan logistik lebih dari 38 pelabuhan yang tersebar di seluruh Indonesia. Didukung oleh puluhan kapal container, ratusan alat berat, dan ribuan container. Dengan fasilitas operasional yang mendukung dan personnel yang handal, kami terus berkembang dan berekspansi untuk meningkatkan servis dan kepuasan pelanggan.",
  homeAboutCTA: "Who We Are",
  homeDataTitle: "Tersebar di Seluruh Indonesia",
  homeDataPort: "Pelabuhan",
  homeDataRoute: "Rute",
  homeDataSentContainer: "Kontainer Terkirim",
  homeDataServing: "Tetap Melayani",
  homeServiceCaption: "Our Services",
  homeServiceTitle: "One Stop Logistics Solutions",
  homeServiceSubtitle: "Beragam layanan untuk seluruh kebutuhan anda",
  homeServiceCTA: "Solution we offer",
  homeServicePTP: "Port to Port",
  homeServicePTPDesc:
    "Pengiriman barang dari satu pelabuhan (port) ke pelabuhan lainnya",
  homeServiceDTD: "Door to Door",
  homeServiceDTDDesc:
    "Pengiriman barang dari pintu awal penjual (tempat penyimpanan) hingga pintu tujuan pembeli.",
  homeServiceAirfreight: "Air Freight",
  homeServiceAirfreightDesc:
    "Transportasi barang Anda dengan aman dan cepat melalui pesawat terbang.",
  homeServiceLandTransport: "Land Transport",
  homeServiceLandTransportDesc:
    "Transportasi barang Anda melalui jalan darat menggunakan Armada kami",
  homeServiceLCL: "Less Container Load",
  homeServiceLCLDesc:
    "Kirim barang Anda tanpa harus memenuhi satu kontainer, lebih hemat & lebih flexibel.",
  homeServiceInternational: "International Shipment",
  homeServiceInternationalDesc:
    "Bekerjasama dengan TOLL Grup kami melayani pengiriman ke luar negara Indonesia.",
  homeServiceInsurance: "Asuransi MAG",
  homeServiceInsuranceDesc:
    "Lindungi barang Anda dari kehilangan dan kerusakan saat proses pengiriman.",
  homeServiceWarehousing: "Warehousing",
  homeServiceWarehousingDesc:
    "Gudang modern dengan lokasi strategis, dan memiliki akses cepat dari depo kontainer.",
  homeNewsCaption: "News & Activities",
  homeNewsTitle: "Our Latest Activities",
  homeNewsCTA: "Our Activities",
  homeNewsCTADetail: "Baca Selengkapnya",
  homeBook: ["Tertarik?", "Mulai Pengirimanmu Sekarang"],
  homeBookCTA: "Book Now",

  // Commitment Page
  commitmentTitle: "Peduli untuk Komunitas dan Planet",
  commitmentHeader1: "Tanggung Jawab",
  commitmentDesc1: [
    "PT SPIL merupakan salah satu perusahaan shipping dan logistic yang menunjukan komitmennya terhadap prinsip-prinsip lingkungan, sosial dan tata kelola (ESG). Dengan pengalaman lebih dari 50 tahun, kami selalu berusaha dan belajar mendalami mengenai dampak sebuah bisnis terhadap bumi, melalui inisiatif ESG yang sudah disusun , kami berusaha untuk selalu melakukan pendekatan yang komprehensif terhadap keberlanjutan.",
    "Menerapkan teknologi dan praktek ramah lingkungan, SPIL memastikan perhitungan jejak karbon dan pengelolaan sumber daya yang bertanggung jawab. Di saat yang sama juga, upaya kami terhadap pemberdayaan sosial berfokus pada penciptaan tempat kerja yang inklusif, menghargai keberagaman, kesejahteraan karyawan dan secara aktif berkontribusi terhadap kemajuan komunitas di sekitarnya.",
    "Kami menjaga transparansi dan akuntabilitas, menumbuhkan kepercayaan di antara pemangku kepentingan dan berusaha melakukan sebuah perubahan yang berarti. Kami percaya tindakan kami ini dapat menginspirasi pihak lain untuk melakukan hal yang sama dan berkolaborasi bersama menuju masa depan yang lebih baik.",
  ],
  commitmentHeader2: "Apa yang sudah kami lakukan ?",
  commitmentDesc2:
    "Di PT SPIL, kami lebih dari sekedar perusahaan; kami adalah perusahaan yang mendukung Lingkungan, Sosial dan Tata kelola (ESG). Dengan komitmen kami yang nyata terhadap Keberlanjutan, tindakan kami mencerminkan keyakinan kami dalam menciptakan dampak positif terhadap dunia tempat kita tinggal sekarang.",
  commitmentSlider1:
    "Kebiasaan Kecil yang Dapat Mengubah Dunia dengan Mesin Daur Ulang Botol Plastik",
  commitmentSlider2:
    "Berkomitmen Melindungi Anak dari Kekerasan di Hari Anak Nasional",
  commitmentSlider3: "Peduli Kesehatan dan Kebersihan Anak",
  commitmentSlider4: "Tumbuhkan Harapan Baru pada Tanaman di Tahun Baru",
  commitmentSlider5: "Menebar Kebaikan Melalui Campaign Zero Hunger",
  commitmentSlider6: "SPIL Peduli Anak Negeri",
  commitmentModalTitle: "Unduh Report ESG",
  commitmentModalTitleCaption:
    "Isi informasi pribadi anda untuk mengunduh Laporan ESG",
  commitmentModalOTPTitle: "Masukan Kode OTP",
  commitmentModalOTPTitleCaption:
    "Cek email anda untuk mendapatkan 6 digit kode OTP.",
  commitmentModalCompanyName: "Nama Perusahaan",
  commitmentModalCompanyNamePlaceholder: "Nama Perusahaan",
  commitmentModalName: "Nama",
  commitmentModalNamePlaceholder: "Nama Lengkap",
  commitmentModalMaleTitle: "Bpk",
  commitmentModalFemaleTitle: "Ibu",
  commitmentModalWANumber: "Nomor telepon",
  commitmentModalWANumberPlaceholder: "Nomor telepon",
  commitmentModalEmail: "Email",
  commitmentModalEmailPlaceholder: "Email Aktif",
  commitmentModalSubmitCTA: "Lanjutkan",
  commitmentModalResendOTP: "Tidak mendapatkan kode?",
  commitmentModalTooltip: "Klik di sini untuk mengunduh",

  // Company Page - About
  companyAboutTitle: "Tentang SPIL",
  companyAboutSubtitle:
    "Ketahui segala informasi terkait dengan PT Salam Pacific Indonesia Lines",
  companyAboutHeader: "Connecting Islands",
  companyAboutDesc: [
    "PT Salam Pacific Indonesia Lines (SPIL) merupakan salah satu perusahaan pelayaran petikemas yang berkantor pusat di Surabaya, Indonesia. SPIL merupakan perusahaan pengiriman petikemas berbasis teknologi terbesar di Indonesia & saat ini melayani lebih dari 40 pelabuhan yang tersebar di seluruh Indonesia.",
    "Perusahaan ini berdiri Pada tahun 1970, didukung dengan jaringan yang luas di seluruh Indonesia serta sumber daya yang berkualitas hingga sampai saat ini  SPIL terus berinovasi dan berkembang untuk memberikan pelayanan yang berkualitas untuk membantu bisnis Anda terus tumbuh dan berkembang. SPIL percaya bahwa inovasi yang berkelanjutan dapat membantu Anda untuk mengatasi kompleksitas lingkungan bisnis saat ini dan kami siap untuk menjadi mitra Anda dalam menghadapi tantangan global.",
  ],
  companyAboutVisi: "Visi",
  companyAboutVisiValue:
    "Menjadi perusahaan pelayaran terbaik yang menggerakkan perekonomian",
  companyAboutMisi: "Misi",
  companyAboutMisiValue:
    "Memberikan solusi transportasi dengan jaringan terluas didukung sumber daya berkualitas untuk kepuasan pelanggan",
  companyAboutSPILValues: "Nilai SPIL",
  companyAboutSPILValueIntegrity: "Integritas",
  companyAboutSPILValueCustomer: "Orientasi Pelanggan",
  companyAboutSPILValueCompetitive: "Kompetitif",
  companyAboutSPILValueTeamwork: "Kerjasama Tim",
  companyAboutSPILValueVisionary: "Visioner",

  // Company Page - History
  companyHistoryTitle: "Sejarah Perusahaan",
  companyHistorySubtitle: "Simak perkembangan SPIL dari masa ke masa.",
  companyHistory1970Title: "Awal Perjalanan",
  companyHistory1970Desc:
    "Perjalanan bermula pada tahun 1970 dengan dibentuknya PT SAMUDERA PACIFIC sebagai perusahaan jasa pengangkutan barang antar pulau.",
  companyHistory1980Title: "Kapal Pertama",
  companyHistory1980Desc:
    "Kapal pertama menandai awal mula lahirnya sebuah perusahaan pelayaran nasional bernama PT SAMUDERA PACIFIC INDAH RAYA (SPIR).",
  companyHistory1984Title: "Ekspansi SPIL",
  companyHistory1984Desc:
    "Perusahaan mengakuisisi saham PT SALAM SEJAHTERA yang berlokasi di Samarinda dan memindahkan kantor pusatnya ke Surabaya sebagai tanda lahirnya PT SALAM PACIFIC INDONESIA LINES (SPIL).",
  companyHistory1996Title: "Kontainerisasi SPIL",
  companyHistory1996Desc:
    "Di tahun 1996, SPIL mengembangkan bisnisnya yang berawal dari kapal curah menjadi pengiriman yang lebih efisien dengan menggunakan kontainer.",
  companyHistory2000Title: "Menjangkau Indonesia Timur",
  companyHistory2000Desc:
    "pada tahun ini PT SPIL merupakan pelayaran pertama yang melayani pengiriman ke Kota Sorong. Berkontribusi dalam pertumbuhan ekonomi dan pasar Indonesia Timur.",
  companyHistory2009Title: "Menghubungkan Indonesia",
  companyHistory2009Desc:
    "Dari kepercayaan pelanggan terhadap kami, di tahun ini SPIL telah memiliki lebih dari 20 cabang yang tersebar di seluruh penjuru negeri.",
  companyHistory2014Title: "Komitmen Kami",
  companyHistory2014Desc:
    "PT SPIL menerima beberapa penghargaan dari Pelindo, Kementrian Perhubungan, dan instansi-instansi lainnya. Hal ini adalah bukti dari komitmen PT SPIL untuk mewujudkan visinya sebagai penggerak perekonomian nasional, di bidang logistik dan transportasi.",
  companyHistory2017Title: "MYSPIL App",
  companyHistory2017Desc:
    "Peluncuran mySPIL sebagai aplikasi pertama di industri pelayaran dan logistik untuk menerapkan digitalisasi yang meningkatkan customer experience.",
  companyHistory2020Title: "International Freight Forwarding",
  companyHistory2020Desc:
    "Terbentuknya PT SPIL Toll Indonesia, sebuah kerja sama antara SPIL dan Toll Group (anggota dari Japan Post).",
  companyHistoryPresentTitle: "All In One Logistic Solutions",
  companyHistoryPresentDesc:
    "PT SPIL Menyediakan berbagai solusi logistik, mulai sea freight, air freight, hingga land transport. Dengan berbagai layanan yang ada, melalui satu platform.",

  // Company Page - Group
  companyGroupTitle: "Grup SPIL",
  companyGroupSubtitle:
    "Dengan perjalanan yang begitu panjang, kami terus berkembang untuk memenuhi semua kebutuhan logistik anda.",
  companyGroupCTA: "See More",
  companyGroupSPIL:
    "SPIL merupakan sebuah perusahaan shipping logistics (Shiplog) yang memiliki jaringan di seluruh wilayah Indonesia, memberikan solusi pengiriman logistik dan pelayaran untuk membantu bisnis Anda terus tumbuh dan berkembang.",
  companyGroupNPTI:
    "NPTI adalah sebuah perusahaan terminal kontainer yang berlokasi di Pelabuhan Tanjung Perak Surabaya, gerbang menuju Indonesia Timur. NPTI berkomitmen untuk menjalankan terminal secara efisien dan efektif menggunakan teknologi modern.",
  companyGroupNPH:
    "Berlokasi di Pelabuhan Tanjung Priok Jakarta, Adipurusa melayani terminal kontainer domestik bekerja sama dengan PT Nusantara Pelabuhan Handal Tbk., sebuah perusahaan terkemuka yang memberikan layanan pelabuhan secara lengkap.",
  companyGroupNYK:
    "Bersama dengan NYK, menyediakan layanan pengiriman domestik secara komprehensif untuk automotif dan alat berat.",
  companyGroupManalagi:
    "Meningkatnya permintaan untuk transportasi breakbulk domestik mendorong SPIL untuk mendirikan divisi bulk-nya, PT Pelayaran Mana Lagi. PT Pelayaran Mana Lagi saat ini memiliki dan mengoperasikan 4 supramax dan 1 kapal handisize.",
  companyGroupDOK:
    "Hadir untuk melayani perbaikan kapal regional dengan mengutamakan keselamatan, kualitas, dan ketepatan waktu. Telah tersertifikasi ISO 9001, ISO 14001, OHSAS 18001 dan SMK3.",
  companyGroupTOLL:
    "Bekerja sama dengan Toll Group, penyedia logistik internasional dengan pengalaman 130 tahun di sektor logistik dan supply chain, PT STI mengelola pengiriman domestik dan internasional, serta logistik kontrak lokal dan distribusi barang.",
  companyGroupVentures:
    "Membuka potensi untuk bertumbuh lebih cepat dalam era supply chain digital. Kami bertujuan untuk mendayagunakan dan mengembangkan ide-ide brilian dan cemerlang sehingga menjadi sebuah bentuk usaha yang sukses.",
  companyGroupTPIL:
    "PT TPIL didirikan pada tahun 2000 sebagai anak perusahaan dari grup SPIL. TPIL berkembang dari perusahaan pengiriman barang domestik menjadi salah satu perusahaan pertama yang menyediakan layanan yang komprehensif. Kami terus berkembang dan memiliki lebih dari 30 cabang di Indonesia.",

  // Company Page - Career
  companyCareerTitle: "Tumbuh Bersama",
  companyCareerListHeader: "Temukan Peluangmu",
  companyCareerTabProfessional: "Profesional",
  companyCareerTabCrew: "Kru Kapal",
  companyCareerTabIntern: "Kadet / Magang",
  companyCareerGreenerHeader:
    "Kami mencari Greeners, untuk bersama-sama menghijaukan samudra. Apakah kamu orangnya?",
  companyCareerGreenerDesc:
    "Temukan peluang baru untuk masa depan Anda dan bersama berkembang dengan nilai-nilai dan budaya kami.",
  companyCareerBusinessHeader: "Bisnis yang Prima",
  companyCareerBusinessDesc:
    "Kami bangga telah menjadi bagian kesuksesan para pelanggan kami selama lebih dari 50 tahun, membuktikan kualitas, konsistensi, dan keberlanjutan bisnis kami untuk terus menggerakan perekonomian nasional.",
  companyCareerGrowthHeader: "Berkembang Bersama",
  companyCareerGrowthDesc:
    "Di SPIL, kami percaya Anda adalah elemen penting dari kesuksesan dan keberlanjutan kami.  Tidak hanya rutinitas pekerjaan, SPIL juga menyediakan berbagai pembelajaran dan pelatihan yang disesuaikan dengan kompetensi dan kebutuhan Anda. Menggapai karir yang Anda dambakan bukan lagi sekedar impian.",
  companyCareerInovationHeader: "Selalu Berinovasi",
  companyCareerInovationDesc:
    "Ibarat ombak laut yang tidak berhenti bergerak, SPIL senantiasa berinovasi untuk mengikuti arus perkembangan jaman. Budaya kerja yang dinamis dan mendorong masing-masing karyawan untuk berani menyuarakan aspirasinya, di sini Anda dapat menjadi bagian dari sebuah perubahan.",
  companyCareerConcernHeader: "Kepedulian Sesama",
  companyCareerConcernDesc:
    "Greeners bukan sekedar warna, sebutan atau ikon, tapi juga kekeluargaan. Kami menjunjung tinggi keberagaman dan peduli dengan sesama. Jarak yang terbentang di antara kantor-kantor kami yang terletak dari Indonesia barat hingga timur, tidak menjadi halangan untuk tetap bersatu dan berjuang bersama.",
  companyCareerMomentHeader: "Momen Spesial di Lingkungan Spesial",

  // Company Page - Career Details
  companyCarrerDetailBack: "kembali ke daftar karir",
  companyCareerDetailJobHeader: "Rincian pekerjaan",
  companyCareerDetailLevel: "Tingkatan",
  companyCareerDetailLocation: "Lokasi",
  companyCareerDetailType: "Tipe",
  companyCareerDetailDesc: "Deskripsi",
  companyCareerDetailQualification: "Kualifikasi",
  companyCareerDetailEducation: "Edukasi",
  companyCareerDetailExperience: "Pengalaman",
  companyCareerDetailCTA: "Lamar Pekerjaan Ini",

  // Service Page - Type
  serviceTypeTitle: "One Stop Logistics Solutions",
  serviceTypeServices: "Services Type",
  serviceTypePTPHeader: "Port to Port",
  serviceTypePTPDesc:
    "Port to Port dapat diartikan dimana shipper atau pengirim barang mengantarkan barang kirimannya ke perusahaan pengiriman yang telah ditunjuk ditempat asal shipper, dan dikirim ke port penerima barang. Serta penerima barang atau consignee tersebut juga mengambil sendiri di port yang telah ditentukan oleh consignee sendiri.",
  serviceTypeDTDHeader: "Door to Door",
  serviceTypeDTDDesc:
    "Door to Door service dalam dunia cargo merupakan sebuah layanan dengan metode pengiriman sebuah barang akan dijemput atau di-pickup pada lokasi pengirim dan diantar menuju lokasi penerima barang atau consignee.",
  serviceTypeLogistic: "Logistics Solutions",
  serviceTypeSeaFreightHeader: "Sea Freight",
  serviceTypeSeaFreightDesc:
    "Sea Freight Services merupakan pengiriman barang melewati perairan laut. Sea Freights merupakan pilihan ekonomis untuk mengirimkan barang dengan volume besar dan jarak yang jauh.",
  serviceTypeAirFreightHeader: "Air Freight",
  serviceTypeAirFreightDesc:
    "Air Freight Services menawarkan pengiriman barang dalam hitungan jam,  jangkuan pengiriman yang lebih luas & aman. Saat ini sudah terintegrasi dan dapat diakses pada aplikasi mySPIL Reloaded.",
  serviceTypeLandTransportHeader: "Land Transport",
  serviceTypeLandTransportDesc:
    "Pengiriman Land Transport mempunyai fleksibilitas yang tinggi berdasarkan waktu, kebutuhan dan Area. Land Transport memiliki jangkauan luas hingga dapat menggapai seluruh wilayah, mulai dari daerah kecil seperti desa, hingga ke seluruh kota. ",
  serviceTypeOther: "Other Services",
  serviceTypeInternationalHeader: "International Freight Forwarder",
  serviceTypeInternationalDesc:
    "International Freight Forwarder adalah istilah yang digunakan untuk menggambarkan pengiriman paket atau kelompok pengiriman paket dimana paket tersebut diambil dari satu negara dan dikirim ke alamat di negara lain. Dalam hal ini, SPIL bersinergi dengan TOLL menjadi SPIL TOLL Indonesia.",
  serviceTypeWarehouseHeader: "Warehouse",
  serviceTypeWarehouseDesc:
    "Sebagai perusahaan layanan logistik yang terintegrasi, SPIL menyediakan gudang modern yang didukung dengan konsep desain modern, lokasi strategis, dan memiliki akses cepat dari depo kontainer untuk pengiriman antar pulau.",
  serviceTypeInsuranceHeader: "Asuransi MAG",
  serviceTypeInsuranceDesc:
    "ASURANSI MAG memberikan perlindungan terhadap kehilangan dan kerusakan pada kargo yang akibat dari bahaya laut yang terjadi selama pengangkutan kargo Anda, baik melalui laut maupun darat. Dengan menyertakan Asuransi MAG  Anda akan lebih tenang ketika mengirimkan barang bersama SPIL. Untuk lebih lanjut dapat dilihat",

  // Service Page - Guide
  serviceGuideHeaderTitle: "Bingung dengan Permasalahanmu ?",
  serviceGuideHeaderFormTitle: "Bagaimana kami bisa membantu ?",
  serviceGuideHeaderFormPlaceholder:
    "Masukkan sebuah topik, seperti pembayaran, pengiriman, segel, dll.",
  serviceGuideHeaderCTA: "Cari",
  serviceGuideTopicHeader: "Cari Berdasarkan Topik",
  serviceGuideSeeMore: "Lihat lebih banyak",
  serviceGuideSeeLess: "Lihat lebih sedikit",
  serviceGuideNoDataFound: "Panduan tidak ditemukan",
  serviceGuideFirstSender: "Pengirim Pertama",
  serviceGuideFirstSenderOpt1: "Pengiriman pertama",
  serviceGuideFirstSenderOpt2: "Tentang dangerous goods",
  serviceGuidePaymentMethod: "Cara Pembayaran",
  serviceGuidePaymentMethodOpt1: "Metode Pembayaran",
  serviceGuidePaymentMethodOpt2: "Pembayaran melalui bank yang diterima SPIL",
  serviceGuidePaymentMethodOpt3: "Akses myFinance",
  serviceGuidePaymentMethodOpt4: "Pembayaran dengan sistem kredit",
  serviceGuideSeal: "Segel",
  serviceGuideSealOpt1: "Pembelian segel pada mySPIL Reloaded",
  serviceGuideSealOpt2: "Pembelian segel pada mySPIL Reloaded (pickup)",
  serviceGuideAirFreight: "Air Freight",
  serviceGuideAirFreightOpt1: "Layanan Air Freight kami",
  serviceGuideAirFreightOpt2: "Cara kerja layanan Air Freight",
  serviceGuideAirFreightOpt3: "Keuntungan menggunakan layanan Air Freight",
  serviceGuideAirFreightOpt4: "Jenis kargo Air Freight",
  serviceGuideMySPILReloaded: "mySPIL Reloaded",
  serviceGuideMySPILReloadedOpt1: "Registrasi akun mySPIL",
  serviceGuideMySPILReloadedOpt2: "Tipe hak akses mySPIL setelah registrasi",
  serviceGuideMySPILReloadedOpt3: "Pengajuan tipe hak akses mySPIL",
  serviceGuideMySPILReloadedOpt4: "Keuntungan menggunakan mySPIL",
  serviceGuideMyBooking: "myBooking",
  serviceGuideMyBookingOpt1: "Fitur Quick Booking",
  serviceGuideMyBookingOpt2: "Tipe hak akses mySPIL setelah registrasi",
  serviceGuideMyBookingOpt3: "Pengajuan tipe hak akses mySPIL",
  serviceGuideMyBookingOpt4: "Keuntungan menggunakan mySPIL",
  serviceGuideMyDO: "myDO",
  serviceGuideMyDOOpt1: "Pengajuan eDO menggunakan Shippper Confirmation",
  serviceGuideMyDOOpt2: "Mendapatkan verification code",
  serviceGuideMyDOOpt3: "Pengajuan perpanjangan eDO",
  serviceGuideMyDOOpt4: "Pembayaran eDO",
  serviceGuideMyShipment: "myShipment",
  serviceGuideMyShipmentOpt1: "Membuat e-SI (Shipping Instruction)",
  serviceGuideMyShipmentOpt2: "Mengakses draft Shipping Instruction",
  serviceGuideMyShipmentOpt3:
    "Mengubah Shipping Instruction yang telah diinput",
  serviceGuideMyShipmentOpt4: "Mengetahui BL sudah diproses",
  serviceGuideMySPILPoints: "mySPIL Points",
  serviceGuideMySPILPointsOpt1: "Tentang mySPIL Points",
  serviceGuideMySPILPointsOpt2: "Cara Mendapatkan mySPIL Points",
  serviceGuideMySPILPointsOpt3: "Perhitungan Nilai mySPIL Points",
  serviceGuideMySPILPointsOpt4: "Masa Berlaku mySPIL Points",
  serviceGuideGuarantee: "Jaminan & Klaim",
  serviceGuideGuaranteeOpt1: "Klaim Jaminan Kontainer pada mySPIL",
  serviceGuideGuaranteeOpt2: "Melakukan Claim pada mySPIL Reloaded",
  serviceGuideBill: "Bill Payments & Topup",
  serviceGuideBillOpt1: "Penjelasan Bill Payments & Topup",
  serviceGuideBillOpt2: "Fitur Bill Payments & Topup",
  serviceGuideBillOpt3: "Melakukan Bill Payments & Topup",
  serviceGuideMySPILCall: "mySPIL Call",
  serviceGuideMySPILCallOpt1: "Fitur mySPIL Call",
  serviceGuideMySPILCallOpt2: "Melakukan panggilan menggunakan mySPIL Call",
  serviceGuideMySPILCallOpt3: "Keuntungan menggunakan mySPIL Call",
  serviceCTAHeader: "Butuh Bantuan Menyelesaikan Permasalahanmu?",
  serviceCTA: "Kontak Kami",

  // Service Page - Guide Detail
  serviceGuideDetailCTABack: "kembali ke daftar topik",
  serviceGuideChoosenTopic: "Topik Pilihan",

  // Service Page - Route and Schedule
  serviceRouteHeaderTitle: "Jadwal Kapal",
  serviceRouteHeaderFormTitlePOL: "Port of Loading",
  serviceRouteHeaderFormPlaceholderPOL: "Pilih Kota Asal",
  serviceRouteHeaderFormTitlePOD: "Port of Discharge",
  serviceRouteHeaderFormPlaceholderPOD: "Pilih Kota Tujuan",
  serviceRouteHeaderCTA: "Book Now",
  serviceRouteAvailableSchedule: "Jadwal Kapal Tersedia",
  serviceRouteFreq: "Frekuensi",
  serviceRouteChooseArea: "Pilih Area Cabang",

  // Contact Us Page
  contactWhatsappDesc:
    "Hubungi Whatsapp kami untuk mendapatkan respon yang akurat",
  contactWhatsappCTA: "Chat Now",
  contactCSDesc:
    "Customer Service SPIL siap membantu segala yang anda butuhkan.",
  contactCSCTA: "Call CS",
  contactEmailTitle: "Kirimkan Pesan",
  contactEmailDesc:
    "Ada beberapa hal yang ingin anda tanyakan? Kirim detailnya melalui pesan.",
  contactEmailCTA: "Send Email",
  contactVisitUs: "Kunjungi Kami",
  contactOurBranch: "Cabang Kami",
  contactContactUs: "Hubungi Kami",
  contactFormName: "Nama",
  contactFormNamePlaceholder: "Nama Lengkap",
  contactFormWA: "Nomor Whatsapp",
  contactFormWAPlaceholder: "Nomor Whatsapp Aktif",
  contactFormEmail: "Email",
  contactFormEmailPlaceholder: "Email Aktif",
  contactFormRequire: "Keperluan",
  contactFormRequirePlaceholder: "Pilih Keperluan",
  contactFormMessage: "Pesan",
  contactFormMessagePlaceholder: "Saya membutuhkan ...",

  // mySPILReloaded Page
  mySPILReloadedTitle: "Layanan Logistik dalam Genggaman",
  mySPILReloadedDesc:
    "PT Salam Pacific Indonesia Lines (SPIL) melalui aplikasi mySPIL Reloaded, terus berinovasi untuk mengembangkan Logistics Super App yang pertama dan terlengkap di Indonesia. mySPIL Reloaded menyediakan layanan multimoda terlengkap untuk bisnis logistik Indonesia, pengiriman barang melalui jalur laut, darat, dan udara. Diluncurkan pada tahun 2017, dan hingga saat ini terus bertransformasi untuk menjawab perubahan zaman dan kebutuhan pelanggan.",
  mySPILReloadedFeature: "Fitur Utama mySPIL Reloaded",
  mySPILReloadedWhy: "Mengapa mySPIL Reloaded?",
  mySPILReloadedEfficientHeader: "Efisien dan Mudah Digunakan",
  mySPILReloadedEfficientDesc:
    "Tampilan aplikasi mySPIL Reloaded yang sederhana dapat memudahkan dan juga menghemat waktu Anda dalam menggunakannya.",
  mySPILReloadedFeatureHeader: "Beragam Fitur Layanan dalam Satu Platform",
  mySPILReloadedFeatureDesc:
    "mySPIL Reloaded dilengkapi dengan beragam fitur untuk dapat melakukan pemesanan dengan praktis, nyaman, dan aman.",
  mySPILReloadedRewardHeader: "Reward dan Bonus Penggunaan",
  mySPILReloadedRewardDesc:
    "Aplikasi mySPIL Reloaded juga dilengkapi reward dan bonus bagi pengguna yaitu mySPIL poin dan mySPIL Referral Program.",
  mySPILReloadedCTA: "Daftar mySPIL Reloaded",

  // News Page
  newsTitle: "Berita & Aktivitas",
  newsCTABack: "kembali ke daftar berita",
  newsLatestNews: "Berita Terbaru",
  newsCategory: "Kategori",
  newsShare: "Bagikan",
  newsArchive: "Arsip",
  newsFilterArchive: "Anda sedang melihat berita pada tahun ",
  newsFilterCategory: "Anda sedang melihat berita dengan kategori ",

  // Outlook Page
  outlookTitle: "Dapatkan Wawasan Baru",
  outlookLatest: "Wawasan Terbaru",
  outlookCTABack: "kembali ke daftar wawasan",

  // SPILDex Page
  spildexTitle: "Stay Updated, Stay Connected",
  spildexDesc:
    "SPILDEX merupakan sebuah layanan khusus untuk Perusahaan dan menyediakan Application Programming Interface (API) yang dapat terintegrasi dengan sistem Enterprise Resource Planning (ERP) dari sebuah perusahaan untuk memudahkan akses data secara real time dengan aman tanpa harus mengetahui proses internal masing-masing perusahaan. Akses data tersebut meliputi vessel schedule, booking list and status, track and trace, statement of account, invoice, dan online payment (via virtual account) dengan kemudahan mengolah informasi sesuai dengan format yang diinginkan oleh perusahaan.",
  spildexVesselHeader: "Vessel Schedule",
  spildexVesselDesc:
    "Anda dapat dengan mudah mengakses jadwal kapal melalui fitur Vessel Schedule dan juga mengetahui aktifitas kapal berangkat, datang dan sandar di aplikasi yang digunakan perusahaan Anda dengan integrasi API dan ERP.",
  spildexPaymentHeader: "e-Payment",
  spildexPaymentDesc:
    "Gunakan fitur pembayaran online (via Virtual Account) untuk melakukan pembayaran yang lebih praktis dan menghemat waktu Anda karena tidak perlu datang ke kantor SPIL.",
  spildexMyBookingHeader: "myBooking",
  spildexMyBookingDesc:
    "Booking semakin mudah dengan fitur Booking List & Status, Anda tidak perlu lagi mengunduh berbagai file dari SPIL hanya untuk melakukan booking, selain itu Anda juga dapat melihat status booking secara real time.",
  spildexShipmentHeader: "e-Shipment Instruction",
  spildexShipmentDesc:
    "Dengan fitur Statement of Account, Anda dapat melihat tagihan pengiriman langsung dari aplikasi perusahaan, tanpa perlu membuka aplikasi lainnya.",
  spildexTraceHeader: "Track and Trace",
  spildexTraceDesc:
    "Gunakan fitur Track & Trace untuk melacak posisi terkini pengiriman Anda dengan memasukkan salah satu informasi dari nomor kontainer, nomor BL, nama kapal, nama pelabuhan asal dan tujuan, atau kode booking.",
  spildexWhy: ["Mengapa", "SPILDex"],
  spildexServiceHeader: "Various Service",
  spildexServiceDesc:
    "Jadwal kapal, Daftar pemesanan, Track & Trace, Riwayat pembayaran, dan Pembayaran online. Semua informasi yang Anda butuhkan ada dalam jangkauan Anda.",
  spildexWebhooksHeader: "Webhooks secara Real-Time",
  spildexWebhooksDesc:
    "Dengan API kami, Anda dapat mengakses data dalam ERP Anda secara real-time dan menerima callback notifikasi seperti pergerakan kontainer, keberangkatan kapal, dan masih banyak lagi.",
  spildexCustomHeader: "Customized",
  spildexCustomDesc:
    "Tampilan setiap fitur di aplikasi dapat disesuaikan dengan format yang diinginkan oleh Perusahaan.",
  spildexOperationHeader: "Operational Efficiency",
  spildexOperationDesc:
    "Dengan semua fitur di atas, maka dapat dipastikan bahwa segala proses khususnya operational akan berjalan lebih efisien.",
  spildexInterest: [
    "Tertarik?",
    "Segera Integrasikan Bisnis Anda dengan SPILDEX",
  ],
  spildexCTA: "Kontak Kami",
};

export default indonesiaWords;
